import request from '@/utils/request'

export function info () {
  return request({
    url: '/info',
    method: 'get'
  })
}

export function auth (type, data) {
  return request({
    url: `/type/${type}/auth`,
    method: 'post',
    data: data
  })
}

export function generateKey (type, data) {
  return request({
    url: `/type/${type}/generate_key`,
    method: 'post',
    data: data
  })
}

export function bind (type, data) {
  return request({
    url: `/type/${type}/bind`,
    method: 'post',
    data: data
  })
}

export function bindInfo () {
  return request({
    url: '/bindInfo',
    method: 'get'
  })
}

export function codeSend (type) {
  return request({
    url: `/type/${type}/codeSend`,
    method: 'post'
  })
}

export function alter (type, data) {
  return request({
    url: `/type/${type}/alter`,
    method: 'post',
    data: data
  })
}

export function backupCodeCheck (type, data) {
  return request({
    url: `/type/${type}/backupCodeCheck`,
    method: 'post',
    data: data
  })
}

export function backupCode () {
  return request({
    url: '/backupCode',
    method: 'post'
  })
}

export function getTransaction (data) {
  return request({
    url: '/transaction',
    method: 'post',
    data: data
  })
}
